import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import has from "lodash.has"
export default function Team() {
  const { alex, anton, innok } = useStaticQuery(graphql`
    {
      alex: file(publicURL: { regex: "/alex-face.jpg/" }) {
        sharp: childImageSharp {
          fixed(width: 200, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      anton: file(publicURL: { regex: "/anton.jpg/" }) {
        sharp: childImageSharp {
          fixed(width: 200, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      innok: file(publicURL: { regex: "/innok-bw.jpg/" }) {
        sharp: childImageSharp {
          fixed(width: 200, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <div className="row">
      <div className="col-lg-12">
        <h1 className="my-4">The Team</h1>
      </div>
      <div className="col-lg-4 col-sm-6 text-center mb-4">
        {has(alex, "sharp.fixed") && (
          <Image
            className="rounded-circle img-fluid d-block mx-auto team-photo"
            fixed={alex.sharp.fixed}
          />
        )}
        <h3 style={{ marginTop: "2%", padding: 0 }}>
          Alex
          <br />
          <small>Principal Developer</small>
        </h3>
        <p> </p>
      </div>
      <div className="col-lg-4 col-sm-6 text-center mb-4">
        {has(innok, "sharp.fixed") && (
          <Image
            className="rounded-circle img-fluid d-block mx-auto team-photo"
            fixed={innok.sharp.fixed}
          />
        )}
        <h3 style={{ marginTop: "2%", padding: 0 }}>
          Innok
          <br />
          <small>Technical Lead</small>
        </h3>
        <p> </p>
      </div>
      <div className="col-lg-4 col-sm-6 text-center mb-4">
        {has(anton, "sharp.fixed") && (
          <Image
            className="rounded-circle img-fluid d-block mx-auto team-photo"
            fixed={anton.sharp.fixed}
          />
        )}
        <h3 style={{ marginTop: "2%", padding: 0 }}>
          Anthony
          <br />
          <small>Online marketing and SEO Expert</small>
        </h3>
        <p> </p>
      </div>
    </div>
  )
}
