import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import { css } from "@emotion/core"
import Image from "gatsby-image"
import has from "lodash.has"
import get from "lodash.get"
import BackgroundImage from "gatsby-background-image"
import Team from "../components/Team"
import CarouselBg from "../components/CarouselBg"
import { Helmet } from "react-helmet"

export default function PrivacyPolicyPage({ data }) {
  return (
    <Layout>
      <Helmet>
      <title>
         Privacy Policy | Softful - Software company Auckland NZ
        </title>
      </Helmet>
      <div>
        <CarouselBg>
          {" "}
          <div className="container">
            <div className="row">
              <div className="col-sm-12 text-right in-pic-height">
                <div className="in-text-holder">Privacy Policy</div>
                <div
                  className="in-pic-1 in-pic-height"
                  style={{ float: "right" }}
                >
                  <Image
                    style={{ height: "100%" }}
                    fluid={data.in_pic_5.sharp.fluid}
                  />
                </div>
              </div>
            </div>
          </div>
        </CarouselBg>
        <div className="breadcrums">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <Link to="/">Home</Link> - Privacy Policy
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-holder">
        <div class="container">
          <div class="row">
            <div class="col-sm-12">
<h3>Personal information policy</h3>              
When this website you agree that Softful Limited collects personal information from you, including information about your:
<ul>
<li>name</li>
<li>contact information</li>
<li>your interactions with us</li>
</ul>
<p>
We collect your personal information in order to:

Improve our products and services
Stay in contact with our existing and potential customers
</p>
<p>
Besides our staff, we share this information with third party services
such as 
<ul>
  <li>Google Analytics in order to evaluate marketing performance of this website.</li>
  <li>Google Inc. to download the fonts and software that forms part of this website</li> 
  
  </ul>
Your web browser sends information to third parties as part of accessing this website or submitting information to this website, Softful Limited does not accept responsibility for those third parties.
The third parties may not be bound by New Zealand law, for example the Privacy Act 1993.
</p>
<p>
We keep your information safe by encrypting it and only disclose it if we are legally required to do so.

You have the right to ask for a copy of any personal information we hold about you, and to ask for it to be corrected or removed.
If you’d like to ask for a copy of your information, or to have it corrected, please contact us at privacy@softful.co.nz, or 09-368-7607.
</p>

<h3>Cookie policy</h3>  
<p>
This website uses cookies, but will function without those. <br></br>

You can disable cookies using the settings of your browser.
</p>
            </div>
          </div>
         
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  {
    in_pic_5: file(publicURL: { regex: "/in_pic_5.png/" }) {
      sharp: childImageSharp {
        fluid(maxWidth: 641) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
